d<template>
  <div class="page-min-height">
    <div v-if="pageDataLoaded === true">
      <div class="about-us__hero">
        <div class="container">
          <div class="row">
            <div class="col col-lg-8">
              <div class="about-us__hero-title mb-2">
                <oc-content :item="$oc.content.aboutPageTitle" />
              </div>
              <div class="about-us__hero-lede">
                <oc-content :item="$oc.content.aboutPageLede" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="about-us__body">
          <div class="row">
            <div class="col col-lg-7">
              <oc-content :item="$oc.content.aboutPageBody" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrchardCoreContentModule } from '../lib/api'
import pageMixin from '../lib/mixins/page'

export default {
  name: 'About',
  mixins: [pageMixin],
  async mounted () {
    try {
      const response = await getOrchardCoreContentModule('about-page')
      this.$oc.setContent(response.data)
      this.pageDataLoaded = true
    } catch {
      this.pageDataLoaded = false
    }
  },
  metaInfo: {
    title: 'Benefit Buddy - About Us'
  }
}
</script>

<style lang="scss" scoped>
.about-us {
  &__hero {
    background-image: url("../assets/img/about-us-background--mobile.png");
    background-size: cover;
    color: $white;
    font-family: $font-family-poppins;
    height: 250px;
    padding-top: 1.4rem;
    text-align: center;

    &-title {
      font-size: 1.6rem;
      font-weight: 600;
    }

    &-lede {
      font-size: 1.3rem;
    }
  }

  &__body {
    padding-top: 2.6rem;
  }

  @include media-breakpoint-up(md) {
    &__hero {
      background-image: url("../assets/img/about-us-background--desktop.png");
      height: 275px;
      padding-top: 3.4rem;
      text-align: left;

      &-title {
        font-size: 1.9rem;
      }

      &-lede {
        font-size: 1.5rem;
      }
    }

    &__body {
      padding-top: 2.6rem;
    }
  }
}
</style>
